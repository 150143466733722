import mixpanel from "mixpanel-browser";
import * as React from "react";
import ReactGA from "react-ga";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Articles,
  Community,
  Coverage,
  Header,
  Investors,
  Jargon,
  Products,
  Roadmap,
} from "../components";
import Footer from "../components/Footer";
import { Hat } from "../components/Header";

const App = () => {
  if (typeof window !== "undefined") {
    ReactGA.initialize("UA-208087322-3");
    ReactGA.pageview(window.location.pathname + window.location.search);
    mixpanel.init("745587ad96b77d8e2923e875c9f1f674", { debug: true });
  }

  React.useEffect(() => {
    AOS.init({
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      duration: 1000,
    });
    AOS.refresh();
  }, []);

  return (
    <React.Fragment>
      <Hat title="Insured Finance" />
      <Header />
      <Jargon />
      <Products />
      <Coverage />
      <Community />
      <Roadmap />
      <Investors />
      <Articles />
      <Footer />
    </React.Fragment>
  );
};

export default App;
